import React, { useState } from "react";
import "./HomePentGrid.css";
import family from "../../../assests/bentoGrid.png";
import logo from "../../../assests/butterflyLogo.svg";
import review from "../../../assests/review.png";
import arrow from "../../../assests/reviewArrow.svg";
import { FaStar } from "react-icons/fa";

const HomePentGrid = () => {
  const [isHover, setIsHover] = useState("");
  const HoverHandler = (e) => {
    setIsHover(e.target.id);
  };
  const exitHoverHandler = () => {
    setIsHover(null);
  };
  return (
    <div>
      <div className="homePentGrid sectionWidth">
        <div className="bentoGrid">
          <div className="bentGrid1">
            <h1>Lorem Ipsum</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry the printing and typesetting industry.
            </p>
            <div className="bentGridImg">
              <img src={family} alt="" />
            </div>
          </div>
          <div className="bentGrid2">
            <div className="">
              <h1>Lorem Ipsum</h1>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry the printing and typesetting industry.
              </p>
            </div>
            <div className="bentGrid2Icon">
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
          </div>
          <div className="bentGrid3">
            <h1>Lorem Ipsum</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry the printing and typesetting industry.
            </p>
            <div className="bentGridImg">
              <img src={family} alt="" />
            </div>
          </div>
          <div className="bentGrid4">
            <div className="">
              <h1>Lorem Ipsum</h1>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry the printing and typesetting industry.
              </p>
            </div>
            <div className="bentGrid2Icon">
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
          </div>
          <div className="bentGrid5">
            <img src={logo} alt="" />
            <h1>Testimonials</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum.
            </p>
          </div>
          <div className="bentGrid6">
            <div className="review">
              <div className="">
                <img
                  id="rev1"
                  onMouseEnter={HoverHandler}
                  onMouseLeave={exitHoverHandler}
                  className="reviewImg1"
                  src={review}
                  alt=""
                />
                <div className="">
                  {isHover === "rev1" && (
                    <>
                      <img className="arrow" src={arrow} alt="" />
                      <h6 className="reviewHover">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </h6>
                    </>
                  )}
                </div>
              </div>
              <img
                id="rev2"
                onMouseEnter={HoverHandler}
                className="reviewImg2"
                src={review}
                alt=""
              />
              <div className="">
                {isHover === "rev2" && (
                  <>
                    <img className="arrow2" src={arrow} alt="" />
                    <h6 className="reviewHover2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </h6>
                  </>
                )}
              </div>
              <img
                id="rev3"
                onMouseEnter={HoverHandler}
                className="reviewImg3"
                src={review}
                alt=""
              />
              <div className="">
                {isHover === "rev3" && (
                  <>
                    <img className="arrow3" src={arrow} alt="" />
                    <h6 className="reviewHover3">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industr.
                    </h6>
                  </>
                )}
              </div>
              <img
                id="rev4"
                onMouseEnter={HoverHandler}
                className="reviewImg4"
                src={review}
                alt=""
              />
              <div className="">
                {isHover === "rev4" && (
                  <>
                    <img className="arrow4" src={arrow} alt="" />
                    <h6 className="reviewHover4">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industr.
                    </h6>
                  </>
                )}
              </div>
              <img
                id="rev5"
                onMouseEnter={HoverHandler}
                className="reviewImg5"
                src={review}
                alt=""
              />
              <div className="">
                {isHover === "rev5" && (
                  <>
                    <img className="arrow5" src={arrow} alt="" />
                    <h6 className="reviewHover5">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industr.
                    </h6>
                  </>
                )}
              </div>
              <img
                id="rev6"
                onMouseEnter={HoverHandler}
                className="reviewImg6"
                src={review}
                alt=""
              />
              <div className="">
                {isHover === "rev6" && (
                  <>
                    <img className="arrow6" src={arrow} alt="" />
                    <h6 className="reviewHover6">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industr.
                    </h6>
                  </>
                )}
              </div>
            </div>
            <div className="border">
              <h1>Lorem Ipsum</h1>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry the printing and typesetting industry.
              </p>
              <div className="bentGridImg">
                <img src={family} alt="" />
              </div>
            </div>
          </div>
          <div className="bentGrid7">
            <div className="">
              <h1>Lorem Ipsum</h1>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry the printing and typesetting industry.
              </p>
            </div>
            <div className="bentGrid2Icon">
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
          </div>
          <div className="bentGrid8">
            <div className="">
              <h1>Lorem Ipsum</h1>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry the printing and typesetting industry.
              </p>
            </div>
            <div className="bentGrid2Icon">
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePentGrid;
