import React, { useEffect } from "react";
import "./Testimonial.css";
import Heading from "../../Reusable/Heading/Heading";
import family from "../../../assests/bentoGrid.png";
import AOS from "aos";
import "aos/dist/aos.css";
import TestimonialCard from "../../Reusable/TestimonialCard/TestimonialCard";

const TestimonialComp = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <div className="marginTop50 sectionWidth">
        <Heading
          Head="Testimonials"
          desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum."
        />
        <TestimonialCard
          head="Lorem Ipsum"
          desc="Lorem Ipsum is simply dummy text of the printing and typesetting
          industry."
          image={family}
          imageClass="testimonialImg"
          Body="TestimonialCompBody paddingBottomNone"
        />
        <TestimonialCard
          head="Lorem Ipsum"
          desc="Lorem Ipsum is simply dummy text of the printing and typesetting
          industry."
          image=""
          imageClass="displayNone"
          Body="TestimonialCompBody"
        />
        <TestimonialCard
          head="Lorem Ipsum"
          desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
          image={family}
          imageClass="testimonialImg"
          Body="TestimonialCompBody paddingBottomNone"
        />
        <TestimonialCard
          head="Lorem Ipsum"
          desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
          image=""
          imageClass="displayNone"
          Body="TestimonialCompBody"
        />
        <TestimonialCard
          head="Lorem Ipsum"
          desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
          image={family}
          imageClass="testimonialImg"
          Body="TestimonialCompBody paddingBottomNone"
        />
        <TestimonialCard
          head="Lorem Ipsum"
          desc="Lorem Ipsum is simply dummy text of the printing and typesetting
          industry."
          image=""
          imageClass="displayNone"
          Body="TestimonialCompBody"
        />
      </div>
    </div>
  );
};

export default TestimonialComp;
