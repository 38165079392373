import React from "react";
import "./GuideLine.css";
import guideImg from "../../../assests/guideline.svg";
import guidelineOverlay from "../../../assests/guidelineOverlay.svg";

const GuideLine = () => {
  return (
    <div>
      <div className="guideLineContainer sectionWidth">
        <div className="guideLineContainerFlex">
          <div className="guideLineContainerFlexLeft">
            <img src={guideImg} alt="" />
          </div>
          <div className="guideLineContainerFlexRight">
            <h1>Urgent support notice; safety first</h1>
            <p>
              “For urgent situations like thoughts of SELF HARM, HARMING OTHERS
              or SUCIDIAL FEELINGS, please avoid using our online counseling
              therapy service. Instead, prioritize your safety by reaching out
              to emergency services are not suitable for emergencies. Your
              safety and well being are paramount, so if it’s an emergency,
              please visit the nearest psychiatric clinic or call this hotline
              for immediate assistance.”
            </p>
            <p className="marginTop20">
              Here are some hotline numbers for mental health support in
              tamilnadu: Tamilnadu mental health helpline: 104 (toll free) Sneha
              suicide prevention helpline: 044-24640050 Arunodhaya suicide
              prevention helpline: 044-24640060
            </p>
            <img className="guidelineOverlay" src={guidelineOverlay} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuideLine;
