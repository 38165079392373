/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import "./NavBar.css";
import logo from "../../assests/logo.png";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../Store/Store";
import { IoMdClose } from "react-icons/io";
import { MdOutlineCalendarToday } from "react-icons/md";
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { GoHomeFill } from "react-icons/go";
import { BsFillGrid1X2Fill } from "react-icons/bs";
import { MdEditCalendar } from "react-icons/md";
import { MdConnectWithoutContact } from "react-icons/md";
import axios from "axios";
// import "animate.css";

const NavBar = () => {
  const dispatch = useDispatch();
  const [isSelect, setSelect] = useState("home");
  const navHandler = (e) => {
    setSelect(e.target.id);
  };

  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  const ScheduleCallHandler = (e) => {
    dispatch(storeAction.isPopUpHander(e.target.id));
  };

  const closeOverlay = () => {
    dispatch(storeAction.isPopUpHander());
  };
  const [formdata, setformdata] = useState({
    name: "",
    gender: "",
    dob: "",
    phone: "",
    available: "",
    timing: "",
    problem: "",
  });
  const [loading, setloading] = useState(false);
  const [status, setstatus] = useState(false);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  const submitbtn = async () => {
    setloading(true);
    setstatus(false);
    var newobj = {
      name: formdata.name,
      gender: formdata.gender,
      dob: formdata.dob,
      phone: formdata.phone,
      available: formdata.available,
      timing: formdata.timing,
      problem: formdata.problem,
    };
    axios
      .post("https://api.trancegroups.com/sendEmail", newobj)
      .then((response) => {
        setloading(false);
        setstatus(true);
        setTimeout(() => {
          setstatus(false);
        }, 5000);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });

    setformdata({
      name: "",
      gender: "",
      dob: "",
      phone: "",
      available: "",
      timing: "",
      problem: "",
    });
  };
  return (
    <div>
      <div className="webHandler">
        <div className="navBar">
          <img src={logo} alt="" />
          <div className="menu">
            <div className="menuItems">
              {isSelect == "home" && <div className="dot"></div>}
              <p
                className={
                  isSelect == "home" ? "menuItemActive" : "menuItemDisable"
                }
                onClick={navHandler}
                id="home"
              >
                Home
              </p>
            </div>
            <div className="menuItems">
              {isSelect == "about" && <div className="dot"></div>}
              <p
                className={
                  isSelect == "about" ? "menuItemActive" : "menuItemDisable"
                }
                onClick={navHandler}
                id="about"
              >
                About Us
              </p>
            </div>
            <div className="menuItems">
              {isSelect == "blog" && <div className="dot"></div>}
              <p
                className={
                  isSelect == "blog" ? "menuItemActive" : "menuItemDisable"
                }
                onClick={navHandler}
                id="blog"
              >
                Blogs
              </p>
            </div>
            <div className="menuItems">
              {isSelect == "contact" && <div className="dot"></div>}
              <p
                className={
                  isSelect == "contact" ? "menuItemActive" : "menuItemDisable"
                }
                onClick={navHandler}
                id="contact"
              >
                Contact Us
              </p>
            </div>
          </div>
          <button id="call" onClick={ScheduleCallHandler}>
            Schedule Call?
          </button>
        </div>
      </div>
      <div className="navBarMob mobileHandler sectionWidth">
        <div className="navBarMobBody">
          <img src={logo} alt="" />
          <HiOutlineMenuAlt3
            id="mobNav"
            onClick={ScheduleCallHandler}
            className="navMobLogo"
          />
        </div>
      </div>
      {isPopUp == "call" && (
        <>
          <div className="scheduleOverlay">
            <div className="scheduleOverlayHead">
              <div className="scheduleOverlayHeadLeft">
                <MdOutlineCalendarToday className="IconClose" />
                <h1>Schedule a Call !</h1>
              </div>
              <div className="scheduleOverlayHeadRight">
                <IoMdClose onClick={closeOverlay} className="IconClose" />
              </div>
            </div>
            <div className="scheduleOverlayBody">
              <img src={logo} alt="" />
              <div className="scheduleOverlayBodyContent">
                <h2>Full Name</h2>
                <input
                  type="text"
                  name="name"
                  onChange={handleChange}
                  defaultValue={formdata.name}
                />
              </div>
              <div className="scheduleOverlayBodyContent">
                <h2>Gender</h2>
                <select
                  name="gender"
                  onChange={handleChange}
                  defaultValue={formdata.gender}
                >
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="scheduleOverlayBodyContent">
                <h2>DOB</h2>
                <input
                  type="date"
                  name="dob"
                  onChange={handleChange}
                  defaultValue={formdata.dob}
                />
              </div>
              <div className="scheduleOverlayBodyContent">
                <h2>Phone Number</h2>
                <input
                  type="text"
                  name="phone"
                  onChange={handleChange}
                  defaultValue={formdata.phone}
                />
              </div>
              <div className="scheduleOverlayBodyContent">
                <h2>When is the most convenient time for you ?</h2>
                <select
                  name="available"
                  onChange={handleChange}
                  defaultValue={formdata.available}
                >
                  <option value="">Select</option>
                  <option value="week days">week days</option>
                  <option value="weekend">weekend</option>
                </select>
              </div>
              <div className="scheduleOverlayBodyContent">
                <h2>Preferred timing</h2>
                <select
                  name="timing"
                  onChange={handleChange}
                  defaultValue={formdata.timing}
                >
                  <option value="">Select</option>
                  <option value="morning 10Am - 12Pm">
                    morning 10Am - 12Pm
                  </option>
                  <option value="morning 12Am - 1Pm">morning 12Am - 1Pm</option>
                  <option value="morning 2Am - 4Pm">morning 2Am - 4Pm</option>
                  <option value="morning 4Am - 8Pm">morning 4Am - 8Pm</option>
                  <option value="morning 8Am - 10Pm">morning 8Am - 10Pm</option>
                </select>
              </div>
              <div className="scheduleOverlayBodyContent">
                <h2>What’s your problem</h2>
                <textarea
                  name="problem"
                  onChange={handleChange}
                  defaultValue={formdata.problem}
                  cols="30"
                  rows="4"
                ></textarea>
              </div>
              {status === true ? (
                <p className="statustext">
                  Your call has been successfully scheduled.
                </p>
              ) : null}

              {loading === false ? (
                <button onClick={submitbtn}>schedule a call !</button>
              ) : (
                <button>Please Wait....</button>
              )}

              <div className="NavIcons">
                <FaFacebookF />
                <FaYoutube />
                <FaLinkedinIn />
                <FaInstagram />
              </div>
            </div>
          </div>
        </>
      )}
      {isPopUp == "call" && <div onClick={closeOverlay} id="overlay"></div>}
      {/* {isPopUp == "mobNav" && ( */}
      <>
        <div
          id="mobNav1"
          className={isPopUp == "mobNav" ? "mobNavbarActive" : "mobNavbar"}
        >
          <div className="mobNavbarTop">
            <div className="mobNavbarImg">
              <img src={logo} alt="" />
              <IoMdCloseCircle
                onClick={ScheduleCallHandler}
                className="mobNavbarIcon"
              />
            </div>
            <div className="mobNavbarMenu">
              <h5
                id="home"
                onClick={navHandler}
                className={
                  isSelect == "home"
                    ? "mobNavbarMenuActive"
                    : "mobNavbarMenuInactive"
                }
              >
                <GoHomeFill className="mobMenuIcon" />
                Home
              </h5>
              <h5
                id="about"
                onClick={navHandler}
                className={
                  isSelect == "about"
                    ? "mobNavbarMenuActive"
                    : "mobNavbarMenuInactive"
                }
              >
                <BsFillGrid1X2Fill className="mobMenuIcon" />
                About
              </h5>
              <h5
                id="blog"
                onClick={navHandler}
                className={
                  isSelect == "blog"
                    ? "mobNavbarMenuActive"
                    : "mobNavbarMenuInactive"
                }
              >
                <MdEditCalendar className="mobMenuIcon" />
                Blog
              </h5>
              <h5
                id="contact"
                onClick={navHandler}
                className={
                  isSelect == "contact"
                    ? "mobNavbarMenuActive"
                    : "mobNavbarMenuInactive"
                }
              >
                <MdConnectWithoutContact className="mobMenuIcon" />
                Contact Us
              </h5>
            </div>
          </div>
          <div className="mobNavbarBottom">
            <p>
              Lorem Ipsum is simply dummy text of the printing text dummy of the
              printin.
            </p>
            <button id="call" onClick={ScheduleCallHandler} className="">
              schedule a call !
            </button>
            <div className="NavIcons">
              <FaFacebookF />
              <FaYoutube />
              <FaLinkedinIn />
              <FaInstagram />
            </div>
          </div>
        </div>
      </>
      {/* )} */}
    </div>
  );
};

export default NavBar;
