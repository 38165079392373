import React from "react";
import "./CardOne.css";
import { useDispatch } from "react-redux";
import { storeAction } from "../../../Store/Store";

const CardOne = (props) => {
  const ScheduleCallHandler = (e) => {
    dispatch(storeAction.isPopUpHander(e.target.id));
  };

  const dispatch = useDispatch();
  return (
    <div>
      <div className="cardOne">
        <div className="cardOneHead">
          <h1>{props.Heading}</h1>
          <p className={props.descClass}>{props.description}</p>
        </div>
        <button id="call" onClick={ScheduleCallHandler}>
          {props.button}
        </button>
        <img className={props.classImage} src={props.image} alt="" />
      </div>
    </div>
  );
};

export default CardOne;
